import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurMenuSection from "../components/Index/OurMenuSection"
import OurPizzeriasSection from "../components/Index/OurPizzerias"
import OurStorySection from "../components/Index/OurStorySection"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <OurMenuSection />
      {/* <OurPizzeriasSection /> */}
      {/* <OurStorySection /> */}
      <Footer />
    </Layout>
  )
}

export default Home
