import { Link } from "gatsby"
import React from "react"

const OurMenuSection = props => {
  return (
    <section className="content-item bg-1">
      <div className="bgDark" />
      <div className="container">
        <div className="content-item-content">
          <div className="content-text">
            {/* <h2>Our Menu</h2> */}
            {/* <hr /> */}
            <p>
              We have had the good fortune of being made privy to a legendary
              pizza makers, secret family recipe. Amongst the hustle and bustle
              of your pizza being made fresh, by hand, right in front of you.
            </p>
            <p>Expect the full authentic pizzeria experience at Yamios.</p>
            <Link to="/order-online" className="default-btn">
              See Menu and Order
              <span />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurMenuSection
