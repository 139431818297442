import { Link } from "gatsby"
import React from "react"
// import ReactWOW from "react-wow"
import Loadable from "@loadable/component"
// import banner1 from "../../assets/images/banner/banner1.jpg"
import banner2 from "../../assets/images/banner/banner2.jpg"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplayHoverPause: false,
  autoplay: true,
  items: 1,
  autoplaySpeed: 1,
  center: true,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
}

const Banner = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])
  return (
    <div>
      <div className="it-services-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="main-banner-content">
                {display ? (
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    {/* <div className="banner-carousel">
                      <img src={banner1} />
                    </div> */}
                    <div className="banner-carousel">
                      <img src={banner2} />
                    </div>
                    <div className="banner-carousel">
                      <h1>Pizza made fresh, by hand, right in front of you!</h1>
                      <h2>
                        Expect the full authentic pizzeria experience at
                        <br />
                        <span>Yamios!</span>
                      </h2>
                    </div>
                  </OwlCarousel>
                ) : (
                  ""
                )}
                <Link to="/order-online" className="default-btn mt-5">
                  Order Online Now!
                  <span />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
